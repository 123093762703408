<template>
  <div v-if="status == 200" class="login-container">
    <div class="invitation-container">
      <div class="img-container">
        <img src="../../../assets/auth-images/create-new-password.png" />
      </div>
    </div>

    <div class="main-div">
      <div class="create-invitaion">
        <div class="inava-icon">
          <img
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]"
            :src="iNavaLogoLight"
             />
        </div>
        <div class="form-heading-container">
          <div class="form-heading">Welcome to iNava!</div>
        </div>
        <div class="form-subheading">
          <div class="header-text">Let's get your profile ready</div>
        </div>
        <div class="form-container">
          <form @keyup.enter="handleSubmit" class="form-field-container">
            <div class="email-input-field">
              <InputVue
                :required="true"
                :showLable="false"
                :min="1"
                type="text"
                :placeholder="'First Name'"
                :max="150"
                :submitted="submitted"
                @focus="handleFocus"
                @onChangeName="onChangeFirstName"
                label="First Name" />
              <div v-if="submitted && isFirstNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>

            <div class="password-input-field">
              <InputVue
                :required="true"
                :min="1"
                :showLable="false"
                :placeholder="'Last Name'"
                type="text"
                :max="150"
                readonly
                @focus="handleFocus"
                :submitted="submitted"
                @onChangeName="onChangeLastName"
                label="Last Name" />
              <div v-if="submitted && isLastNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
            <div class="password-input-field">
              <InputVue
                :required="true"
                :showLable="false"
                type="password"
                :placeholder="'Password'"
                :max="150"
                :submitted="submitted"
                @focus="handleFocus"
                readonly
                @onChangeName="onChangePassword"
                label="Password" />
              <div
                v-if="
                  submitted && !isPasswordInvalid && passwordInvalidCode === 1
                "
                class="required-error">
                Password does not meet the security requirements.
                <b-tooltip type="is-dark" multilined size="is-large">
                  <template v-slot:content>
                    - Length must be between 8 and 100 characters.<br />Must
                    have: <br />- One uppercase letter, <br />- One lowercase
                    letter, and <br />- One digit.
                  </template>
                  <b-icon icon="information" size="is-small"> </b-icon>
                </b-tooltip>
              </div>
            </div>

            <div class="password-input-field">
              <InputVue
                :required="true"
                :min="1"
                :showLable="false"
                :placeholder="'Confirm password'"
                type="password"
                :max="150"
                :submitted="submitted"
                @focus="handleFocus"
                readonly
                @onChangeName="onChangeConfirmPassword"
                label="Confirm password" />
              <div
                v-if="submitted && !isMatching && !isConfirmPasswordInvalid"
                class="required-error">
                New Password and Confirm Password are not matching.
              </div>
            </div>

            <div class="sign-in-container">
              <div class="buttons">
                <b-button
                  type="is-primary"
                  class="sign-in-button"
                  expanded
                  @click.prevent="handleSubmit">Next</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="status == 401 || status == 404" class="login-container">
    <div class="invitation-container">
      <div class="img-container">
        <img src="../../../assets/auth-images/create-new-password.png" />
      </div>
    </div>
    <div class="main-div">
      <div class="create-invitaion-1">
        <div class="inava-icon">
          <img
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]"
            :src="iNavaLogoLight"
             />
        </div>
        <div class="form-heading-container">
          <div class="form-heading">Invitation link expired.</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputVue from '../../../views/Admin/components/inputNew.vue'
import { mapActions } from 'vuex'
import { metaTitle } from '../../../constant/data'
import { re, allowedNamePattern } from '@/util/util'
export default {
  name: 'SSOForm',
  metaInfo () {
    return {
      title: metaTitle.profileSetup
    }
  },
  components: {
    InputVue
  },
  props: {
    iNavaLogoLight: {
      type: String
    },

    productLogoLight: {
      type: Boolean
    }
  },
  data () {
    return {
      modal: true,
      submitted: false,
      user: {
        firstName: null,
        lastName: null,
        password: null,
        token: null
      },
      confirmPassword: null,
      isFirstNameInvalid: true,
      isLastNameInvalid: true,
      isFirstNamePatternInvalid: false,
      isLastNamePatternInvalid: false,
      isPasswordInvalid: true,
      isConfirmPasswordInvalid: true,
      isMatching: false,
      status: null,
      passwordInvalidCode: null,
      namePatternErrorMessage: 'Invalid input. Only letters, numbers, spaces, - and _ are allowed.'
    }
  },

  mounted () {
    this.user.token = this.$route.params.token
    this.verifyToken()
  },
  methods: {
    ...mapActions('invitation', [
      'verifyInvitationToken',
      'addInvitedUserDetails'
    ]),
    async verifyToken () {
      const res = await this.verifyInvitationToken({
        invitationToken: this.$route.params.token,
        slug: window.location.host.split('.')[0]
      })
      this.status = res
    },
    onChangeFirstName (name, isInvalid) {
      this.user.firstName = name
      this.isFirstNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isFirstNamePatternInvalid = true
      } else {
        this.isFirstNamePatternInvalid = false
      }
    },
    onChangeLastName (name, isInvalid) {
      this.user.lastName = name
      this.isLastNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isLastNamePatternInvalid = true
      } else {
        this.isLastNamePatternInvalid = false
      }
    },
    onChangePassword (name, isValid) {
      this.user.password = name
      this.isPasswordInvalid = isValid
      if (!re.test(this.user.password)) {
        // this.isPasswordInvalid = false
        this.passwordInvalidCode = 1
      } else {
        this.passwordInvalidCode = null
      }
      this.checkMatch()
    },
    onChangeConfirmPassword (name, isValid) {
      this.confirmPassword = name
      this.isConfirmPasswordInvalid = isValid
      this.checkMatch()
    },
    checkMatch () {
      if (this.user.password !== this.confirmPassword) {
        this.isMatching = false
      } else {
        this.isMatching = true
      }
    },
    handleFocus (e) {
      e.removeAttribute('readonly')
    },
    handleSubmit (e) {
      this.submitted = true
      if (
        !this.isPasswordInvalid &&
        !this.isFirstNameInvalid &&
        !this.isLastNameInvalid &&
        !this.isFirstNamePatternInvalid && 
        !this.isLastNamePatternInvalid &&
        !this.isConfirmPasswordInvalid &&
        this.isMatching &&
        this.passwordInvalidCode == null
      ) {
        this.addInvitedUserDetails(this.user)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-img-container {
  display: flex;
  justify-content: center;
  .powered-by-text {
    text-align: center;
    width: 100%;
    font: normal normal normal 12px/14px Quicksand;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal normal 13px/15px Quicksand;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal normal 14px/18px Quicksand;
    }
  }
}

.logo-img {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  span {
    border-right: 0.75px solid #c7c7c7;
    margin-left: 15px;
    height: 30px;
    margin-top: 5px;
  }
  .left-logo {
    width: 50%;
  }
  .right-logo {
    width: 50%;
    margin-left: 15px;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 145px;
    height: 60px;
    span {
      height: 50px;
    }
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 180px;
    height: 70px;
    span {
      height: 60px;
    }
  }
}
.invitation-container {
  width: 50%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-left-radius: 26px;
}

.img-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
}

.text-details {
  margin: 2% 10% 2% 10%;
  text-align: center;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  // color: #303030;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal normal 15px/19px Quicksand;
    font-weight: 700;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal normal 18px/23px Quicksand;
  }
}

.corousal-text {
  height: 50%;
  background-color: #0e0f1b;
}
.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-corousal {
  height: 100%;
}
.corousal-item-new {
  height: 100%;
}

.form-container {
  height: auto;
}
.required-error {
  height: 24px;
  position: absolute;
  margin-top: -17px !important;
  // @media (min-width: 1450px) and (max-width: 1700px) {
  //   margin-top: 75px !important;
  // }
  //@media screen and (min-width: 1700px), screen and (min-height: 1080px) {
  //   margin-top: 84px !important;
  // }
}
.form-field-container {
  height: 100%;
}
.sign-in-container {
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 18px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 60px;
  }
  .sign-in-button {
    background: #00218a 0% 0% no-repeat padding-box;
    height: 35px;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 7px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
    }
  }
  .sign-in-button:hover {
    background: #00218a 0% 0% no-repeat padding-box;
  }
}

.email-field {
  margin-top: 0.5rem;
}
.corousal-item {
  height: 100% !important;
}

.form-heading {
  text-align: left;
  font: normal normal 24px/30px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 27px/34px Quicksand;
    font-weight: 600;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 34px/43px Quicksand;
    font-weight: 600;
  }
}
.header-text {
  text-align: left;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  color: #303030;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 15px/19px Quicksand;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 18px/23px Quicksand;
  }
}
.main-div {
  width: 50%;
  height: 100%;
  color: #828282;
  background-color: #ac57e4;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #fff;
  border-top-right-radius: 26px;
}
.create-invitaion {
  width: 68%;
  margin-left: 18%;
  @media (min-width: 1500px) {
    width: 70%;
    margin-left: 18%;
  }
}
.create-invitaion-1 {
  width: 68%;
  margin-left: 18%;
  height: 70%;
  @media (min-width: 1500px) {
    width: 70%;
    margin-left: 18%;
  }
}

.inava-icon {
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 130px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 190px;
  }
}

.inava-form-top-logo {
  width: 160px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 200px;
    margin-top: 14%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 250px;
    margin-top: 15%;
  }
}

.inava-form-top-logo-1 {
  width: 60px;
  border-radius: 50%;
  background: #e8e8f0 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 7%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 70px;
    margin-top: 9%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 84px;
    margin-top: 12%;
  }
}
.form-heading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 29px;
  }
}

.email-input-field {
  width: 100%;
  margin-top: 30px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 32px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 36px;
  }
}
.password-input-field {
  width: 100%;
  margin-top: 30px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 32px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 36px;
  }
}

.form-subheading {
  margin-top: 7px;
  width: 100%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 10px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 15px;
  }
}
.check-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.is-medium {
  min-height: 400px !important;
}
</style>
<!-- <style lang="scss" src="../../views/Login/Login.scss" scoped /> -->
