var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.status == 200)?_c('div',{staticClass:"login-container"},[_vm._m(0),_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"create-invitaion"},[_c('div',{staticClass:"inava-icon"},[_c('img',{class:[
            _vm.productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
          ],attrs:{"src":_vm.iNavaLogoLight}})]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"form-container"},[_c('form',{staticClass:"form-field-container",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"email-input-field"},[_c('InputVue',{attrs:{"required":true,"showLable":false,"min":1,"type":"text","placeholder":'First Name',"max":150,"submitted":_vm.submitted,"label":"First Name"},on:{"focus":_vm.handleFocus,"onChangeName":_vm.onChangeFirstName}}),(_vm.submitted && _vm.isFirstNamePatternInvalid)?_c('div',{staticClass:"required-error"},[_vm._v(_vm._s(_vm.namePatternErrorMessage))]):_vm._e()],1),_c('div',{staticClass:"password-input-field"},[_c('InputVue',{attrs:{"required":true,"min":1,"showLable":false,"placeholder":'Last Name',"type":"text","max":150,"readonly":"","submitted":_vm.submitted,"label":"Last Name"},on:{"focus":_vm.handleFocus,"onChangeName":_vm.onChangeLastName}}),(_vm.submitted && _vm.isLastNamePatternInvalid)?_c('div',{staticClass:"required-error"},[_vm._v(_vm._s(_vm.namePatternErrorMessage))]):_vm._e()],1),_c('div',{staticClass:"password-input-field"},[_c('InputVue',{attrs:{"required":true,"showLable":false,"type":"password","placeholder":'Password',"max":150,"submitted":_vm.submitted,"readonly":"","label":"Password"},on:{"focus":_vm.handleFocus,"onChangeName":_vm.onChangePassword}}),(
                _vm.submitted && !_vm.isPasswordInvalid && _vm.passwordInvalidCode === 1
              )?_c('div',{staticClass:"required-error"},[_vm._v(" Password does not meet the security requirements. "),_c('b-tooltip',{attrs:{"type":"is-dark","multilined":"","size":"is-large"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" - Length must be between 8 and 100 characters."),_c('br'),_vm._v("Must have: "),_c('br'),_vm._v("- One uppercase letter, "),_c('br'),_vm._v("- One lowercase letter, and "),_c('br'),_vm._v("- One digit. ")]},proxy:true}],null,false,787611028)},[_c('b-icon',{attrs:{"icon":"information","size":"is-small"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"password-input-field"},[_c('InputVue',{attrs:{"required":true,"min":1,"showLable":false,"placeholder":'Confirm password',"type":"password","max":150,"submitted":_vm.submitted,"readonly":"","label":"Confirm password"},on:{"focus":_vm.handleFocus,"onChangeName":_vm.onChangeConfirmPassword}}),(_vm.submitted && !_vm.isMatching && !_vm.isConfirmPasswordInvalid)?_c('div',{staticClass:"required-error"},[_vm._v(" New Password and Confirm Password are not matching. ")]):_vm._e()],1),_c('div',{staticClass:"sign-in-container"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"sign-in-button",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("Next")])],1)])])])])])]):(_vm.status == 401 || _vm.status == 404)?_c('div',{staticClass:"login-container"},[_vm._m(3),_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"create-invitaion-1"},[_c('div',{staticClass:"inava-icon"},[_c('img',{class:[
            _vm.productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
          ],attrs:{"src":_vm.iNavaLogoLight}})]),_vm._m(4)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invitation-container"},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":require("../../../assets/auth-images/create-new-password.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-heading-container"},[_c('div',{staticClass:"form-heading"},[_vm._v("Welcome to iNava!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-subheading"},[_c('div',{staticClass:"header-text"},[_vm._v("Let's get your profile ready")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invitation-container"},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":require("../../../assets/auth-images/create-new-password.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-heading-container"},[_c('div',{staticClass:"form-heading"},[_vm._v("Invitation link expired.")])])
}]

export { render, staticRenderFns }