<template>
  <div class="login-container">
    <div class="login-text">
      <div class="img-container">
        <img
          src="../../assets/auth-images/check-email.png"
          width="385px"
          height="400px"
        />
      </div>
    </div>
    <div class="main-div">
      <div class="recovered-token-success">
        <div class="inava-icon">
          <img
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]"
            :src="iNavaLogoLight"
          />
        </div>
        <div class="form-heading-container">
          <div class="form-heading">
            Hurray! Check your email for a password reset link.
          </div>
        </div>

        <div class="sign-in-container">
          <div class="buttons">
            <b-button
              type="is-primary"
              class="form-button"
              expanded
              @click="goSignIn()"
              >Sign In</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

/** This component is used to render the Login form */

<script>
import { metaTitle } from '../../constant/data'

/** This component is rendered when a reset link is sent to the user's email address */
export default {
  metaInfo() {
    return {
      title: metaTitle.recoverPassword
    }
  },

  props: {
    iNavaLogoLight: {
      type: String
    },

    productLogoLight: {
      type: Boolean
    }
  },
  methods: {
    /**
     * This method redirects the user to the login page
     * @public
     */
    goSignIn() {
      this.$router.push('/login')
    },
    /**
     * This method redirects the user to the *'/resetPassword'* page
     * @public
     */
    goToResetPassword() {
      this.$router.push('/resetPassword')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-text {
  width: 50%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-left-radius: 26px;
}
.img-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 45%;
  left: 10%;
  transform: translate(0, -50%);

  img {
    width: 385px;
    height: 350px;
    object-fit: contain;

    @media (min-width: 1450px) and (max-width: 1700px) {
      width: 430px;
      height: 450px;
    }
    @media (min-width: 1700px) {
      width: 545px;
      height: 585px;
    }
  }
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // background-color: #fff;
}

.check-email {
  height: 17%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  p {
    text-align: left;
    font: normal normal 11px/15px Quicksand;
    font-weight: 500;
    letter-spacing: 0px;
    color: #303030;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 12px/16px Quicksand;
    }
    @media (min-width: 1700px) {
      font: normal normal 14px/18px Quicksand;
    }

    span {
      color: #535eeb;
      cursor: pointer;
    }
  }
}

.inava-form-top-logo {
  width: 160px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 200px;
    margin-top: 14%;
  }
  @media (min-width: 1700px) {
    width: 250px;
    margin-top: 15%;
  }
}

.inava-form-top-logo-1 {
  width: 60px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 70px;
    margin-top: 14%;
  }
  @media (min-width: 1700px) {
    width: 84px;
    margin-top: 15%;
  }
}

.powered-by-text {
  text-align: center;
  font: normal normal normal 12px/14px Quicksand;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal normal 13px/15px Quicksand;
  }
  @media (min-width: 1700px) {
    font: normal normal normal 14px/18px Quicksand;
  }
}
.form-button {
  background: #00218a 0% 0% no-repeat padding-box;
  height: 37px;
  text-align: center;
  font: normal normal 15px/19px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 18px/23px Quicksand;
    height: 42px;
  }
  @media (min-width: 1700px) {
    font: normal normal 18px/23px Quicksand;
    height: 56px;
  }
}
.form-button:hover {
  background: #00218a 0% 0% no-repeat padding-box;
}

.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: #c4c8f8 0% 0% no-repeat padding-box !important;
  border-color: transparent;
  color: #fff;
}

.form-heading {
  text-align: left;
  font: normal normal 24px/30px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;
  cursor: pointer;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 27px/34px Quicksand;
    font-weight: 600;
  }
  @media (min-width: 1700px) {
    font: normal normal 34px/43px Quicksand;
    font-weight: 600;
  }
}
.header-text {
  text-align: left;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  color: #303030;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 15px/19px Quicksand;
  }
  @media (min-width: 1700px) {
    font: normal normal 18px/23px Quicksand;
  }
}
.main-div {
  width: 50%;
  height: 100%;
  color: #828282;
  background-color: #ac57e4;
  // display: flex;
  // justify-content: space-around;
  // flex-direction: column;
  background-color: #fff;
  border-top-right-radius: 26px;
}
.recovered-token-success {
  width: 68%;
  margin-left: 18%;
  // display: flex;
  // flex-direction: column;
  height: auto;
  margin-top: 15%;

  @media (min-width: 1500px) {
    width: 70%;
    margin-left: 18%;
  }
}

.inava-icon {
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 130px;
  }
  @media (min-width: 1700px) {
    height: 190px;
  }
}

.form-heading-container {
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 47px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 20px;
    margin-top: 62px;
  }
  @media (min-width: 1700px) {
    margin-top: 75px;
  }
}

.form-field {
  height: 7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1500px) and (max-width: 1800px) {
    height: 7%;
  }
  @media (min-width: 1900px) {
    height: 7%;
  }
  @media (min-height: 900px) {
    justify-content: flex-start;
  }
}

.check-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.remember-password {
  text-align: left;
  font: normal normal 12px/14px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #535eeb;
  opacity: 1;
  cursor: pointer;
  @media (min-width: 1500px) {
    font: normal normal normal 14px/18px Quicksand;
  }
}

.sign-in-container {
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 18px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 78px;
  }
  @media (min-width: 1700px) {
    margin-top: 70px;
  }
  .sign-in-button {
    background: #00218a 0% 0% no-repeat padding-box;
    height: 35px;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 7px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
    }
    @media (min-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
    }
  }
  .sign-in-button:hover {
    background: #00218a 0% 0% no-repeat padding-box;
  }
}

.logo-img {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  span {
    border-right: 0.75px solid #c7c7c7;
    margin-left: 15px;
    height: 30px;
    margin-top: 5px;
  }
  .left-logo {
    width: 50%;
  }
  .right-logo {
    width: 50%;
    margin-left: 15px;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 145px;
    height: 60px;
    span {
      height: 50px;
    }
  }
  @media (min-width: 1700px) {
    width: 180px;
    height: 70px;
    span {
      height: 60px;
    }
  }
}
</style>
