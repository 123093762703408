<template>
  <div class="login-container">
    <div class="login-text">
      <div class="img-container">
        <img src="../../assets/auth-images/create-new-password.png" />
      </div>
    </div>
    <div class="main-div">
      <div class="login-form-1">
        <div class="inava-icon">
          <div>
            <img
              :class="[
                productLogoLight
                  ? 'inava-form-top-logo'
                  : 'inava-form-top-logo-1'
              ]"
              :src="iNavaLogoLight"
              
            />
          </div>
        </div>
        <div class="password-expired-container">
          <div class="reset-password-text">
            <p>
              Your password has been successfully reset. Please continue to Sign
              In page.
            </p>
          </div>
        </div>

        <div class="sign-in-container">
          <div class="buttons">
            <b-button
              type="is-primary"
              class="sign-in-button"
              expanded
              @click="goSignIn()"
              >Sign In</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import { metaTitle } from '../../constant/data'
/** This component contains the code for the page shown after a successful password reset */
export default {
  metaInfo() {
    return {
      title: metaTitle.resetSuccess
    }
  },
  props: {
    iNavaLogoLight: {
      type: String
    },

    productLogoLight: {
      type: Boolean
    }
  },
  name: 'SuccessfulReset',
  methods: {
    /** This method redirects you to the login page */
    goSignIn() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-text {
  width: 50%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-left-radius: 26px;
}

.logo-img {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  span {
    border-right: 0.75px solid #c7c7c7;
    margin-left: 15px;
    height: 30px;
    margin-top: 5px;
  }
  .left-logo {
    width: 50%;
  }
  .right-logo {
    width: 50%;
    margin-left: 15px;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 145px;
    height: 60px;
    span {
      height: 50px;
    }
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 180px;
    height: 70px;
    span {
      height: 60px;
    }
  }
}
.img-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: #c4c8f8 0% 0% no-repeat padding-box !important;
  border-color: transparent;
  color: #fff;
}

.main-div {
  width: 50%;
  height: 100%;
  color: #828282;
  background-color: #ac57e4;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #fff;
  border-top-right-radius: 26px;
}
.login-form-1 {
  width: 68%;
  margin-left: 18%;
  height: 75%;

  @media (min-width: 1500px) {
    width: 70%;
    margin-left: 18%;
  }
}
.inava-icon {
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 130px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 190px;
  }
}

.inava-form-top-logo-1 {
  height: 60px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 70px;
    margin-top: 14%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 84px;
    margin-top: 15%;
  }
}

.inava-form-top-logo {
  width: 160px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 200px;
    margin-top: 14%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 250px;
    margin-top: 15%;
  }
}

.password-expired-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.3em;
  .reset-password-text {
    p {
      text-align: left;
      font: normal normal 24px/30px Quicksand;
      font-weight: 600;
      letter-spacing: 0px;
      color: #00218a;
      margin-top: 1em;
      @media (min-width: 1450px) and (max-width: 1700px) {
        font: normal normal 27px/34px Quicksand;
        font-weight: 600;
      }
      @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
        font: normal normal 34px/43px Quicksand;
        font-weight: 600;
      }
    }
  }
}

.sign-in-container {
  height: 37px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 18px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 27px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 41px;
  }
  .sign-in-button {
    background: #00218a 0% 0% no-repeat padding-box;
    height: 35px;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 7px;
    margin-top: 20px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
      margin-top: 25px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
      margin-top: 30px;
    }
  }
  .sign-in-button:hover {
    background: #00218a 0% 0% no-repeat padding-box;
  }
}

.is-medium {
  min-height: 400px !important;
}
</style>
