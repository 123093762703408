var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_vm._m(0),_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"login-form-1"},[_c('div',{staticClass:"inava-icon"},[_c('div',[_c('img',{class:[
              _vm.productLogoLight
                ? 'inava-form-top-logo'
                : 'inava-form-top-logo-1'
            ],attrs:{"src":_vm.iNavaLogoLight}})])]),_vm._m(1),_c('div',{staticClass:"sign-in-container"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"sign-in-button",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.goSignIn()}}},[_vm._v("Sign In")])],1)])])]),_c('div')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-text"},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":require("../../assets/auth-images/create-new-password.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"password-expired-container"},[_c('div',{staticClass:"reset-password-text"},[_c('p',[_vm._v(" Your password has been successfully reset. Please continue to Sign In page. ")])])])
}]

export { render, staticRenderFns }