<template>
  <div class="login-container">

    <div class="login-home">

      <div class="form-component">

        <login-form
          v-if="$route.path.endsWith('login')"
          :iNavaLogoLight="iNavaLogoLight"
          :platformIntroText="platformIntroText"
          :productLogoLight="product_logo_light" />
        <reset-password-form
          :iNavaLogoLight="iNavaLogoLight"
          :productLogoLight="product_logo_light"
          v-if="$route.path.includes('resetPassword')" />
        <reset-token-success
          :iNavaLogoLight="iNavaLogoLight"
          :productLogoLight="product_logo_light"
          v-if="$route.path.endsWith('recoverPassword')" />

        <reset-token-form
          :iNavaLogoLight="iNavaLogoLight"
          :productLogoLight="product_logo_light"
          v-if="$route.path.endsWith('forgotPassword')" />
        <ssovue
          :iNavaLogoLight="iNavaLogoLight"
          :productLogoLight="product_logo_light"
          v-if="$route.path.endsWith('profile-setup')" />
        <password-recovered-success
          :iNavaLogoLight="iNavaLogoLight"
          :productLogoLight="product_logo_light"
          v-if="$route.path.endsWith('passwordRecovered')" />

        <invitation-vue
          :iNavaLogoLight="iNavaLogoLight"
          :productLogoLight="product_logo_light"
          v-if="$route.path.includes('invite')" />
      </div>

      <div class="footer-column">
        <div class="columns">
          <div class="column is-6 rights logo-img-container">
            <div>
              <div class="powered-by-text">Powered By</div>

              <div class="logo-img">
                <img class="left-logo" src="../../assets/sga_logo_light.png" />
                <span></span>
                <img class="right-logo" src="../../assets/inava_logo_p.svg" />
              </div>
            </div>
          </div>
          <div class="tnc-and-privacy-container">
            <div class="privacy"><router-link :to="{ name: 'privacyPolicy' }">Privacy Policy</router-link></div>
            <span class="separator">|</span>
            <div class="tnc"><router-link :to="{ name: 'tnc' }">Terms and Conditions</router-link></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyPartnerVue from '../../components/Common/CompanyPartner/CompanyPartner.vue'
import { metaTitle } from '../../constant/data'
import LoginForm from '../../components/login/LoginForm.vue'
import ResetPasswordForm from '../../components/login/ResetPasswordForm'
import resetTokenForm from '../../components/login/ResetTokenForm'
import ResetTokenSuccess from '../../components/login/ResetTokenSuccess'
import ssovue from '../../components/login/SSOUserForm.vue'
import PasswordRecoveredSuccess from '../../components/login/PasswordRecoveredSuccess'
import InvitationVue from '../User/Invitation/Invitation.vue'
import { mapActions, mapState } from 'vuex'
import Snackbar from '../../components/Snackbar'

/**
 * This is the component rendered on the Login page.
 */
export default {
  name: 'Login',
  metaInfo () {
    return {
      title: metaTitle.login
    }
  },
  components: {
    LoginForm,
    CompanyPartnerVue,
    ResetPasswordForm,
    resetTokenForm,
    ResetTokenSuccess,
    ssovue,
    PasswordRecoveredSuccess,
    InvitationVue
  },
  data () {
    return {
      iNavaLogoLight: null,
      platformIntroText: '',
      product_logo_light: false
    }
  },
  computed: {
    ...mapState('user', ['client_logos'])
  },

  watch: {
    client_logos () {
      if (this.client_logos) {
        if (this.client_logos.product_logo_light) {
          this.product_logo_light = true
        }
        this.iNavaLogoLight =
          this.client_logos.product_logo_light ||
          require('@/assets/inava-icon.png')
      } else {
        this.iNavaLogoLight = require('@/assets/inava-icon.png')
      }
      if (this.client_logos && this.client_logos.platform_intro_text) {
        this.platformIntroText = this.client_logos.platform_intro_text
      } else {
        this.platformIntroText =
          'Competitive Intelligence Platform to monitor the competitive landscape for your products vis-à-vis your competition.'
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    if (to.query.user) {
      Snackbar({
        message: 'Invalid login email. Please contact administrator.',
        type: 'is-danger'
      })
      next('/login')
    } else if (to.query.sso) {
      Snackbar({ message: 'Some error occured.', type: 'is-danger' })
      next('/login')
    } else {
      next()
    }
  },
  mounted () {
    this.getClientLogos()
  },
  methods: {
    ...mapActions('user', ['getClientLogos'])
  }
}
</script>

<style lang="scss">
.login-container {
  height: 100vh;
  width: 100%;
  background: #535eeb url('../../assets/login-container-sm.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.login-home {
  height: 83vh;
  min-height: 570px;
  width: 70%;
  opacity: 0.28;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 26px;
  opacity: 1;
  position: absolute;
  @media (min-width: 1450px) and (max-width: 1575px) {
    min-height: 650px;
  }
  @media (min-width: 1575px) and (max-width: 1700px) {
    min-height: 680px;
  }
  @media (min-width: 1700px) {
    min-height: 900px;
  }
}
.rights {
  display: flex;
  justify-content: space-around;
  // text-align: center;
  font: normal normal 13px/17.5px Quicksand;
  font-weight: 500;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 16px/20px Quicksand;
  }
  @media (min-width: 1700px) {
    font: normal normal 16px/20px Quicksand;
  }
}
.terms {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font: normal normal 13px/17.5px Quicksand;
  font-weight: 500;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 16px/20px Quicksand;
  }
  @media (min-width: 1700px) {
    font: normal normal 16px/20px Quicksand;
  }
}
.footer-column {
  height: 12%;
  // background-color: rgb(97, 58, 58);
}
.form-component {
  background-color: #fff;
  height: 88%;
  position: relative;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  .powered-by-text {
    text-align: center;
    width: 100%;
    font: normal normal normal 11px/14px Quicksand;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal normal 13px/15px Quicksand;
    }
    @media (min-width: 1700px) {
      font: normal normal normal 14px/18px Quicksand;
    }
  }
}

.logo-img {
  width: 100px;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 7px;

  span {
    border-right: 0.75px solid #c7c7c7;
    margin-left: 15px;
    height: 14px;
    margin-top: 2px;
  }
  .left-logo {
    width: 50%;
  }
  .right-logo {
    width: 50%;
    margin-left: 15px;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 150px;
    height: 25px;
    span {
      height: 25px;
      margin-top: 4px;
    }
  }
  @media (min-width: 1700px) {
    width: 240px;
    height: 29px;
    margin-top: 12px;

    span {
      height: 30px;
      margin-top: 5px;
    }
  }
}

.tnc-and-privacy-container {
  display: flex;
  font-size: 12px;
  justify-content: center;
  width: 50%;
  align-items: center;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font-size: 13px;
  }
  @media (min-width: 1700px) {
    font-size: 14px;
  }
  .separator {
    margin: 0 5px;
  }
  a {
    color: var(--inava-gray);
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
