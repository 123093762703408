<template>
  <div class="login-container">
    <div class="left-img-container">
      <div class="img-container">
        <img src="../../assets/auth-images/forgot-password.png" />
      </div>
    </div>
    <div class="main-div">
      <div class="login-form-1">
        <div class="inava-icon">
          <img
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]"
            :src="iNavaLogoLight" />
        </div>

        <div class="form-heading-container">
          <div class="form-heading">Forgot Password?</div>
        </div>
        <div class="form-subheading">
          <div class="header-text">
            Enter the email associated with your account and we will send an
            email with instructions to reset your password.
          </div>
        </div>

        <div class="email-input-field">
          <InputVue
            :required="true"
            :showLable="false"
            :min="1"
            type="email"
            :placeholder="'Email'"
            :max="150"
            @onEnter="handleSubmit"
            :submitted="submitted"
            @onChangeName="onChangeEmail"
            label="Email" />
            <span
              v-if="messages.length"
              class="required-error">
              {{messages[0]}}
            </span>
        </div>
        <div class="form-field">
          <div class="check-col">
            <div class="remember-password" @click="goSignIn()">
              Wait, I remember my password
            </div>
          </div>
        </div>
        <div class="sign-in-container">
          <div class="buttons">
            <b-button
              type="is-primary"
              class="form-button"
              expanded
              @click.prevent="handleSubmit">Send Link</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { clearStorage } from '../../util/util'
import InputVue from '../../views/Admin/components/inputNew.vue'

/** This component is used to render the Login form */
export default {
  name: 'LoginForm',
  components: {
    InputVue
  },

  props: {
    iNavaLogoLight: {
      type: String
    },

    productLogoLight: {
      type: Boolean
    }
  },
  data () {
    return {
      form: {
        email: ''
      },
      submitted: false,
      isEmailInvalid: true,
      messages: []
    }
  },
  methods: {
    /** This method redirects the user to the sign in page
     * @public
     */
    goSignIn () {
      this.$router.push('/login')
    },
    /** This method clears all messages if any are present
     * @public
     */
    clearMessage () {
      this.messages = []
    },
    /** This method checks the validity of the email
     * @public
     */
    onChangeEmail (name, isValid) {
      this.form.email = name
      this.isEmailInvalid = isValid
      this.clearMessage()
    },
    /**
     * This method submits the form
     * @public
     */
    async handleSubmit (e) {
      this.submitted = true
      if (!this.isEmailInvalid) {
        try {
          const formData = this.form
          formData.clientSlug = window.location.host.split('.')[0]
          const response = await this.$http.post(
            '/password/sendResetLink',
            formData
          )
          if (response.data.success) {
            this.$router.push('/recoverPassword')
          } else {
            clearStorage(false)
            this.messages = []
            this.messages.push(response.data.info)
          }
        } catch (error) {
          clearStorage(false)
          this.messages = []
          this.messages.push('Network error, please try again')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.left-img-container {
  width: 50%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-left-radius: 26px;
}
.img-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
}

.main-div {
  width: 50%;
  height: 100%;
  color: #828282;
  background-color: #ac57e4;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #fff;
  border-top-right-radius: 26px;
}

.form-button {
  background: #00218a 0% 0% no-repeat padding-box;
  height: 37px;
  text-align: center;
  font: normal normal 15px/19px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 18px/23px Quicksand;
    height: 42px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 18px/23px Quicksand;
    height: 56px;
  }
}
.form-button:hover {
  background: #00218a 0% 0% no-repeat padding-box;
}
.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: #c4c8f8 0% 0% no-repeat padding-box !important;
  border-color: transparent;
  color: #fff;
}

.inava-form-top-logo {
  width: 160px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 200px;
    margin-top: 14%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    // height: 84px;
    width: 250px;
    margin-top: 15%;
  }
}

.inava-form-top-logo-1 {
  width: 60px;
  margin-top: 16%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 70px;
    margin-top: 18%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 84px;
    margin-top: 19%;
  }
}

.form-heading {
  text-align: left;
  font: normal normal 24px/30px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 27px/34px Quicksand;
    font-weight: 600;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 34px/43px Quicksand;
    font-weight: 600;
  }
}
.header-text {
  text-align: left;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  color: #303030;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 15px/19px Quicksand;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 18px/23px Quicksand;
  }
}

.login-form-1 {
  width: 68%;
  margin-left: 18%;
  // display: flex;
  // flex-direction: column;
  height: 100%;

  @media (min-width: 1500px) {
    width: 70%;
    margin-left: 18%;
  }

  .custom-input-field {
    margin: 20px 0px 0px 0px
  }
}

.inava-icon {
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 130px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 190px;
  }
}

.form-heading-container {
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 20px;
    margin-top: 20px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 29px;
  }
}
.form-subheading {
  height: 36px;
  margin-top: 12px;
  width: 100%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 42px;
    margin-top: 15px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 50px;
    margin-top: 22px;
  }
}
.email-input-field {
  width: 100%;
  height: 38px;
  margin-top: 52px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 45px;
    margin-top: 62px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 60px;
    margin-top: 72px;
  }
}

.form-field {
  height: 15px;
  margin-top: 22px;
  width: 100%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 20px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 22px;
  }
}

.check-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.remember-password {
  text-align: left;
  font: normal normal 12px/14px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #535eeb;
  opacity: 1;
  cursor: pointer;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal normal 14px/18px Quicksand;
    margin-top: 0.2rem;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal normal 14px/18px Quicksand;
    margin-top: 0.4rem;
  }
}
.sign-in-container {
  height: 37px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 18px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 27px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 41px;
  }
  .sign-in-button {
    background: #00218a 0% 0% no-repeat padding-box;
    height: 35px;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 7px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
    }
  }
  .sign-in-button:hover {
    background: #00218a 0% 0% no-repeat padding-box;
  }
}

.is-medium {
  min-height: 400px !important;
}
</style>
