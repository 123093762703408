<template>
  <div class="login-container">
    <div class="login-text">
      <div v-if="clientName === 'sga'" class="corousal-container">
        <b-carousel
          class="login-corousal"
          :indicator="false"
          :arrow="false"
          :pause-info="false">
          <b-carousel-item
            class="corousal-item-new"
            v-for="(carousel, i) in carousels"
            :key="i"
            style="height: 100%"><div class="content-container">
              <div class="img-container">
                <img :src="carousel.img" />
              </div>

              <div class="img-text-container">{{ carousel.heading }}</div>
              <div class="text-details">
                {{ carousel.text }}
              </div>
            </div>
          </b-carousel-item>
        </b-carousel>
      </div>
      <div v-else class="sap-img-container">
        <img src="../../assets/auth-images/sap-img.png" />

        <div class="text-container">
          <div class="img-text-container">
            {{ platformIntroText }}
          </div>
        </div>
      </div>
    </div>
    <div class="main-div">
      <div class="login-form-1">
        <div class="inava-icon">
          <img
            :src="iNavaLogoLight"
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]" />
        </div>
        <div class="form-heading-container">
          <div class="form-heading">Sign in to your Account</div>
        </div>
        <div class="form-subheading">
          <div class="header-text">
            Please Log in to explore the competitive intelligence platform
          </div>
        </div>
        <!-- <div class="form-component"> -->
        <form @keyup.enter="handleSubmit" class="form-component">
          <div
            class="field-error"
            v-for="(message, index) in messages"
            :key="index">
            {{ message }}
          </div>
          <div class="email-input-field">
            <InputVue
              :required="true"
              :showLable="false"
              :min="1"
              type="email"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeEmail"
              label="Email"
              uniqueClassId="1" />
          </div>
          <div class="password-input-field">
            <InputVue
              :required="true"
              :showLable="false"
              :min="1"
              type="password"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangePassword"
              label="Password"
              uniqueClassId="1" />
          </div>
          <div class="form-field">
            <div class="forgotpassword">
              <a @click="goToResetPassword">Forgot Password</a>
            </div>
          </div>

          <div class="sign-in-container">
            <div class="buttons">
              <b-button
                type="is-primary"
                class="sign-in-button"
                expanded
                @click.prevent="handleSubmit">Sign In</b-button>
            </div>
          </div>
        </form>
        <!-- </div> -->
        <div v-if="sso.url" class="sign-in-okta-container">
          <a :href="sso.url" class="sign-in-Okta">
            Sign In with {{ sso.type }}
          </a>
          <!-- <div class="buttons"> -->
          <!-- <b-button
              type="is-primary"
              class="sign-in-Okta"
              expanded
              @click="routeToOkta"
            >
              Sign In with OKTA
            </b-button> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { clearStorage } from '../../util/util'
import InputVue from '../../views/Admin/components/inputNew.vue'

/** This component is used to render the Login form */
export default {
  name: 'LoginForm',
  components: {
    InputVue
  },

  props: {
    /** The list of tab names */
    platformIntroText: {
      type: String
    },
    iNavaLogoLight: {
      type: String
    },

    productLogoLight: {
      type: Boolean
    }
  },
  data () {
    return {
      carousels: [
        {
          heading: 'Real-time Intelligence',
          text: 'Seamless E2E dataflow pipelines that support the continuous flow of real-time intellligence',
          img: require('../../assets/auth-images/login-slide-1.png')
        },
        {
          heading: 'Powerful Visualizations',
          text: 'Rich, intractive, purpose-built dashboards with click-through insights and powerful analytical frameworks',
          img: require('../../assets/auth-images/login-slide-2.png')
        },
        {
          heading: 'Single line of sight',
          text: 'Extensive antologies aided bt AI/ML algorithms to identify common themes and patterns across data points',
          img: require('../../assets/auth-images/login-slide-3.png')
        }
      ],
      sso: {
        url: null,
        type: null
      },
      form: {
        email: '',
        password: ''
      },
      submitted: false,
      isEmailInvalid: true,
      isPasswordInvalid: true,
      messages: [' ']
      // iNavaLogoLight: null,
      // platformIntroText: ''
    }
  },

  computed: {
    ...mapState('user', ['verifiedClient']),
    ...mapState('filter', ['subscriptionRange', 'subscriptionGranulartiy']),
    ...mapState('user', ['client_logos']),
    clientName: function () {
      return window.location.host.split('.')[0]
    }
  },

  async beforeMount () {
    const res = await this.getSSO(window.location.host.split('.')[0])
    if (res.sso_type) {
      this.sso.url =
        process.env.NODE_ENV === 'development'
          ? `${process.env.VUE_APP_URL}:${process.env.VUE_APP_PORT}/api/v1/login/saml/consume`
          : `${window.location.origin}/api/v1/login/saml/consume`
      this.sso.type = res.sso_type
    }
  },

  mounted () {
    // this.$refs.email.focus()
    window.localStorage.setItem('user-token', '')
  },
  methods: {
    ...mapActions('user', ['setUserDetails', 'getSSO']),
    ...mapActions('common', ['update_user_preference_mode']),
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('filters', [
      'updateSubscriptionRange',
      'updateSubscriptionGranularity'
    ]),

    /** This method redirects you to the *'/forgotPassword'* url.
     * @public
     */
    goToResetPassword () {
      this.$router.push('/forgotPassword')
    },
    /**
     * This method is used to validate the email entered
     * @public
     */
    onChangeEmail (name, isValid) {
      this.form.email = name
      this.isEmailInvalid = isValid
    },
    /**
     * This method is used to validate the password entered
     * @public
     */
    onChangePassword (name, isValid) {
      this.form.password = name
      this.isPasswordInvalid = isValid
    },

    // routeToOkta() {
    //   // this.$router.go(`${this.sso.url}`)
    // },
    /**
     * This method is used to submit the credentials
     * @public
     */
    async handleSubmit (e) {
      this.submitted = true
      if (!(this.isEmailInvalid || this.isPasswordInvalid)) {
        try {
          const formData = this.form
          // formData.clientSlug = this.$router.currentRoute.path.split('/')[1]
          formData.clientSlug = window.location.host.split('.')[0]
          const response = await this.$http.post('/login', formData)
          if (response.data.success) {
            // localStorage.setItem('user-token', response.data.data.token)
            localStorage.setItem(
              'user',
              JSON.stringify(response.data.data.user)
            )
            window.localStorage.setItem(
              'access-token',
              'Bearer ' + response.data.data.token
            )
            window.localStorage.setItem(
              'refresh-token',
              response.data.data.refreshToken
            )
            localStorage.setItem('token-expire', 'false')
            if (response.data.data.user.preferences) {
              window.localStorage.setItem(
                'preference_mode',
                response.data.data.user.preferences.preference_mode
              )
              this.$http.defaults.headers.common.Authorization =
                'Bearer ' + response.data.data.token
              this.update_user_preference_mode(
                response.data.data.user.preferences.preference_mode
              )
            }
            this.setUserDetails(response.data.data.user)
            this.updateEvent({
              userId: response.data.data.user.userId,
              event: 'click',
              label: 'login',
              pageUrl: window.location.href
            })
            this.$router.push(
              this.$route.query.redirect || {
                name: response.data.data.user.modules.find(
                  (m) => m.module_name !== 'settings'
                ).module_name
              }
            )
          } else {
            if (response.data.status === 403) {
              clearStorage(false)
              this.messages = []
              this.messages.push(
                'Your subscription has expired. Please contact the Administrator'
              )
            } else if (response.data.status === 405) {
              clearStorage(false)
              this.messages = []
              this.messages.push(
                'This account has been blocked. Please contact administrator.'
              )
            } else if (!response.data.status || response.data.status === 500) {
              clearStorage(false)
              this.messages = []
              this.messages.push('Something went wrong, please try again later')
            } else if (response.data.status === 406) {
              clearStorage(false)
              this.messages = []
              if (Number(response.data.info.count) === 3) {
                this.messages.push(
                  'This Account has been locked temporarily please try again after sometime.'
                )
              } else {
                this.messages.push(
                  `Incorrect credentials. Your account will be temporarily blocked after  ${
                    3 - response.data.info.count
                  } more unsuccessful attempts`
                )
              }
            } else {
              clearStorage(false)
              this.messages = []
              this.messages.push('Incorrect credentials. Please try again.')
            }
          }
        } catch (error) {
          clearStorage(false)
          this.messages = []
          this.messages.push('Network error, please try again')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-text {
  width: 50%;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 26px;
}

.content-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sap-img-container {
  margin-top: 75px;

  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 85px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 112px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 270px;
    width: 409px;
    object-fit: contain;

    @media (min-width: 1450px) and (max-width: 1700px) {
      height: 315px;
      width: auto;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      height: 365px;
      width: auto;
    }
  }
}
.corousal-container {
  background-color: #fff;
  width: 85%;
  margin-top: 60px;
  margin-left: 7%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 115px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 160px;
  }
}

.img-container {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 270px;
    width: 300px;
    object-fit: contain;

    @media (min-width: 1450px) and (max-width: 1700px) {
      height: 315px;
      width: 365px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      height: 365px;
      width: 409px;
    }
  }
}
.text-container {
  height: 180px;
  margin-top: 2vh;
  width: 100%;
  display: block;
}
.img-text-container {
  text-align: center;
  font: normal normal 24px/30px Quicksand;
  font-weight: 700;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;
  margin-top: 5px;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal normal 27px/34px Quicksand;
    font-weight: 700;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 34px/43px Quicksand;
    font-weight: 700;
  }
}

.text-details {
  margin: 2% 10% 2% 10%;
  text-align: center;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  opacity: 1;
  color: #303030;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal normal 15px/19px Quicksand;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal normal 18px/23px Quicksand;
  }
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.corousal-item-new {
  height: 100%;
}

.email-field {
  margin-top: 0.5rem;
}
.corousal-item {
  height: 100% !important;
}

.form-heading {
  text-align: left;
  font: normal normal 24px/30px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 27px/34px Quicksand;
    font-weight: 600;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 34px/43px Quicksand;
    font-weight: 600;
  }
}
.header-text {
  text-align: left;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  opacity: 1;
  color: #303030;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 15px/19px Quicksand;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 18px/23px Quicksand;
  }
}

.main-div {
  width: 50%;
  height: 100%;
  color: #828282;
  justify-content: space-around;
  background-color: #fff;
  border-top-right-radius: 26px;
}
.login-form-1 {
  width: 70%;
  margin: 0 auto;
  height: 100%;
}

.inava-icon {
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 130px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 190px;
  }
}

.inava-form-top-logo {
  width: 160px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 200px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 250px;
  }
}

.inava-form-top-logo-1 {
  width: 60px;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 70px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 84px;
  }
}

.form-heading-container {
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 20px;
    margin-top: 20px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 29px;
  }
}
.form-subheading {
  height: 36px;
  margin-top: 7px;
  width: 100%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 42px;
    margin-top: 10px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 50px;
    margin-top: 15px;
  }
}
.form-component {
  height: auto;
}
.email-input-field {
  width: 100%;
  // height: 38px;
  margin-top: 5px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    // height: 45px;
    margin-top: 5px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    // height: 60px;
    margin-top: 10px;
  }
}
.password-input-field {
  width: 100%;
  // height: 38px;
  margin-top: 34px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 28px;
    // height: 45px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    // height: 60px;
    margin-top: 42px;
  }
}
.form-field {
  height: 10px;
  margin-top: 10px;
  width: 100%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 14px;
    margin-top: 10px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 20px;
    height: 18px;
  }
}

.forgotpassword {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: left;
  font: normal normal 12px/14px Quicksand;
  font-weight: 500;
  letter-spacing: 0px;
  color: #535eeb;
  opacity: 1;
  a{
    cursor: pointer;
  }
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 13px/16px Quicksand;
    font-weight: 500;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 14px/18px Quicksand;
    font-weight: 500;
  }
}
.sign-in-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 18px;
  background: rgba(196, 200, 248, 0.28);
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 27px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 41px;
  }
  .sign-in-button {
    background: #00218a 0% 0% no-repeat padding-box;
    height: 35px;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 7px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
    }
  }
  .sign-in-button:hover {
    background: #00218a 0% 0% no-repeat padding-box;
  }
}

.sign-in-okta-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 24px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 34px;
  }

  .sign-in-Okta {
    border-radius: 7px;
    opacity: 0.8;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #00218a;
    opacity: 1;
    height: 35px;
    background: rgba(196, 200, 248, 0.28);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
    }
  }

  // .button.is-primary {
  //   background: rgba(196, 200, 248, 0.28);
  // }
}

.field-error {
  font-size: 11px;
  // width: 340px;
  color: red !important;
  height: 26px;
  // position: absolute;
  // margin-top: 5px;
  align-items: center;
  @media screen and (min-width: 1450px) and (max-width: 1700px) {
    font-size: 11px;
    height: 28px;
    // width: 360px;
    margin-top: 8px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    // width: 450px;
    height: 30px;
    font-size: 11px;
    margin-top: 12px;
    // margin-top: -50px;
  }
}
.is-medium {
  min-height: 400px !important;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  .powered-by-text {
    text-align: center;
    width: 100%;
    font: normal normal normal 12px/14px Quicksand;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal normal 13px/15px Quicksand;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal normal 14px/18px Quicksand;
    }
  }
}

.logo-img {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  span {
    border-right: 0.75px solid #c7c7c7;
    margin-left: 15px;
    height: 30px;
    margin-top: 5px;
  }
  .left-logo {
    width: 50%;
  }
  .right-logo {
    width: 50%;
    margin-left: 15px;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 145px;
    height: 60px;
    span {
      height: 50px;
    }
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 240px;
    height: 29px;
    span {
      height: 60px;
    }
  }
}
</style>
