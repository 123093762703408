<template>
  <!-- :class="customBorder ? 'darkBorder' : ''" -->
  <div class="custom-input-field">
    <input
      placeholder=" "
      :type="type"
      v-model="name"
      class="form-control"
      :disabled="disabled"
      :readonly="readonly"
      @keydown.enter="onEnterFun"
      :password-reveal="passwordReveal"
      @input="handleInput"
      @focus="handleFocus"
    />
    <label>{{ label }}</label>
    <span
      v-if="!$v.name.required && submitted && required"
      class="required-error"
    >
      {{ label }} is required
    </span>
    <span
      v-if="!$v.name.minLength && required && submitted"
      class="required-error"
      >{{ label }} must be at least {{ min }} characters.</span
    >
    <span
      v-if="!$v.name.maxLength && required && submitted"
      class="required-error"
      >{{ label }} must be at max {{ max }} characters.</span
    >
    <span v-if="!$v.name.email && required && submitted" class="required-error"
      >Email is invalid.</span
    >
    <span
      v-if="!$v.name.alphaNum && required && submitted"
      class="required-error"
      >Only alphanumeric characters allowed.</span
    >
    <span v-if="!$v.name.url && submitted" class="required-error"
      >Enter correct url.</span
    >
  </div>
</template>
<script>
import {
  required,
  alphaNum,
  email,
  minLength,
  maxLength,
  url
} from 'vuelidate/lib/validators'
// password-reveal
/** This component is used to take in user inputs */
export default {
  name: 'InputNew',
  props: {
    /** This prop contains the text for the label of the input */
    label: {
      type: String
    },
    /** Unsure */
    onChangeName: {
      type: Function
    },
    /** This prop indicates what type of input is being created */
    type: {
      type: String,
      default: 'text'
    },
    uniqueClassId: {
      type: String,
      default: 'text'
    },
    /** This prop indicates the value of the input */
    value: {
      default: null
    },
    /** This prop indicates whether or not the password entered should be visible or not */
    passwordReveal: {
      type: Boolean,
      default: false
    },
    /** This prop indicates whether or not a form submission has been attempted */
    submitted: {
      type: Boolean,
      default: false
    },
    /** This prop indicates the min value of the input */
    min: {
      type: Number,
      default: null
    },
    /** This prop indicates the max value of the input */
    max: {
      type: Number,
      default: null
    },
    /** This prop indicates whether a value is required for this input or not */
    required: {
      type: Boolean,
      default: false
    },
    /** This prop indicates whether a value is valid or not */
    isValid: {
      type: Function
    },
    /** This prop indicates whether a value is editable or not */
    readonly: {
      type: Boolean,
      default: false
    },
    /** This prop contains the placeholder text for the input */
    placeholder: {
      type: String,
      default: ''
    },
    /** This prop indicates whether or not this input is editable */
    disabled: {
      type: Boolean,
      default: false
    },
    /** This prop indicates whether or not a label must be shown for this input */
    showLable: {
      type: Boolean,
      default: true
    },
    /** This prop defines the behavior of this input when Enter is pressed (?) */
    onEnter: {
      type: Function
    },
    /** This prop indicates whether or not a custom border should be applied to the input */
    customBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: this.value
    }
  },
  watch: {
    name() {
      this.$v.$touch()
      this.$emit('onChangeName', this.name, this.$v.name.$invalid)
    },
    value() {
      this.name = this.value
    }
  },
  mounted() {
    this.$v.$touch()
    this.$emit('onChangeName', this.name, this.$v.name.$invalid)
  },
  validations() {
    return {
      // eslint-disable-next-line no-constant-condition
      name: {
        required: this.required ? required : false,
        email: this.type === 'email' ? email : false,
        url: this.type === 'url' ? url : false,
        alphaNum: this.type === 'password' || 'email' ? false : alphaNum,
        minLength: minLength(this.min),
        maxLength: maxLength(this.max)
      }
    }
  },
  methods: {
    onEnterFun() {
      this.$emit('onEnter')
    },
    handleInput(data) {
      this.$emit('input', data)
    },
    handleFocus(event) {
      this.$emit('focus', event.target)
    }
  }
}
</script>

<style lang="scss" scoped>
.control.has-icons-left.select select {
  padding-left: 0.8em !important;
}

.foo > .placeholder {
  color: red;
  padding-left: 0.8em !important;
}

.required-error {
  color: red;
  position: absolute;
  font-size: 12px;
}
.required {
  color: red;
}

.custom-input-field {
  margin: 20px 0;
  position: relative;
  display: inline-block;
  color: #7f8fa4;
  font-size: 10px;
  font-family: Quicksand;
  display: flex;

  span {
    margin-top: 42px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      margin-top: 50px;
    }
    @media (min-width: 1700px) {
      margin-top: 60px;
    }
  }
}

label {
  padding: 9px 7px;
  pointer-events: none;
  position: absolute;
  left: 15px;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
  text-align: left;
  font: normal normal normal 13px/16px Quicksand;
  letter-spacing: 0px;
  color: #303030;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    padding: 12px 10px;
    font: normal normal normal 15px/19px Quicksand;
  }
  @media (min-width: 1700px) {
    padding: 15px 10px;
    font: normal normal normal 18px/23px Quicksand;
  }
}

input {
  padding: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 7px;
  background: #fcfcfc;
  opacity: 1;
  height: 36px;
  width: 100%;
  font: normal normal normal 13px/16px Quicksand;

  letter-spacing: 0px;
  color: #303030;
  opacity: 1;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 42px;
    font: normal normal normal 15px/19px Quicksand;
  }
  @media (min-width: 1700px) {
    height: 56px;
    font: normal normal normal 18px/23px Quicksand;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:hover {
  background: #fff;
  background-color: #ffffff;
}
input:active {
  background: #fff;
  background-color: #ffffff;
}

input:focus + label,
input:not(:placeholder-shown) + label {
  opacity: 1;
  transform: scale(0.75) translateY(-70%) translateX(-14px);
  background: #ffffff;
  padding: 0px 6px;

  // background-color: -internal-light-dark(
  //   rgb(232, 240, 254),
  //   rgba(70, 90, 126, 0.4)
  // ) !important;
}

input:focus {
  outline: none !important;
  border: 1px solid #c7c7c7;
  box-shadow: 0 0 2px #719ece;
  background: #ffffff;
}

input:-internal-autofill-selected + label {
  opacity: 1;
  transform: scale(0.75) translateY(-70%) translateX(-14px);
  background: #ffffff;
  padding: 0px 6px;
}
</style>
