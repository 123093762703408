var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_vm._m(0),(_vm.isResetTokenValid)?_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"create-new-password-form"},[_c('div',{staticClass:"inava-icon"},[_c('img',{class:[
            _vm.productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
          ],attrs:{"src":_vm.iNavaLogoLight}})]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"form-container"},[_c('form',{staticClass:"form-field-container",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"email-input-field"},[_c('InputVue',{attrs:{"required":true,"showLable":false,"type":"password","submitted":_vm.submitted,"placeholder":'New password',"max":150,"label":"New Password"},on:{"onChangeName":_vm.onChangePassword}}),(
                _vm.submitted && !_vm.isPasswordInvalid && _vm.passwordInvalidCode === 1
              )?_c('div',{staticClass:"required-error"},[_vm._v(" Password does not meet the security requirements. "),_c('b-tooltip',{attrs:{"type":"is-dark","multilined":"","size":"is-large"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" - Length must be between 8 and 100 characters."),_c('br'),_vm._v("Must have: "),_c('br'),_vm._v("- One uppercase letter, "),_c('br'),_vm._v("- One lowercase letter, and "),_c('br'),_vm._v("- One digit. ")]},proxy:true}],null,false,787611028)},[_c('b-icon',{attrs:{"icon":"information","size":"is-small"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"password-input-field"},[_c('InputVue',{attrs:{"required":true,"showLable":false,"min":1,"type":"password","submitted":_vm.submitted,"placeholder":'Confirm password',"max":150,"label":"Confirm Password"},on:{"onChangeName":_vm.onChangeConfirmPassword}}),(_vm.submitted && !_vm.isConfirmPasswordInvalid && !_vm.isMatching)?_c('div',{staticClass:"required-error"},[_vm._v(" New Password and Confirm Password are not matching. ")]):_vm._e()],1),_c('div',{staticClass:"sign-in-container"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"sign-in-button",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("Reset Password")])],1)])])])])]):_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"create-new-password-form"},[_c('div',{staticClass:"inava-icon"},[_c('img',{class:[
            _vm.productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
          ],attrs:{"src":_vm.iNavaLogoLight}})]),_c('div',{staticClass:"form-heading-container-1"},[_c('div',{staticClass:"form-heading"},[_vm._v(" Your reset password request has expired. ")]),_c('div',{staticClass:"sign-in-container"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"sign-in-button",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.goSignIn()}}},[_vm._v("Sign In")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-new-password-container"},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":require("../../assets/auth-images/create-new-password.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-heading-container"},[_c('div',{staticClass:"form-heading"},[_vm._v("Welcome Back!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-subheading"},[_c('div',{staticClass:"header-text"},[_vm._v("Create New Password")])])
}]

export { render, staticRenderFns }