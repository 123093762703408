<template>
  <div class="login-container">
    <div class="create-new-password-container">
      <div class="img-container">
        <img src="../../assets/auth-images/create-new-password.png" />
      </div>
    </div>
    <div v-if="isResetTokenValid" class="main-div">
      <div class="create-new-password-form">
        <div class="inava-icon">
          <img
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]"
            :src="iNavaLogoLight"
          />
        </div>

        <div class="form-heading-container">
          <div class="form-heading">Welcome Back!</div>
        </div>
        <div class="form-subheading">
          <div class="header-text">Create New Password</div>
        </div>

        <div class="form-container">
          <form @keyup.enter="handleSubmit" class="form-field-container">
            <div class="email-input-field">
              <InputVue
                :required="true"
                :showLable="false"
                type="password"
                :submitted="submitted"
                :placeholder="'New password'"
                :max="150"
                @onChangeName="onChangePassword"
                label="New Password"
              />
              <div
                v-if="
                  submitted && !isPasswordInvalid && passwordInvalidCode === 1
                "
                class="required-error"
              >
                Password does not meet the security requirements.
                <b-tooltip type="is-dark" multilined size="is-large">
                  <template v-slot:content>
                    - Length must be between 8 and 100 characters.<br />Must
                    have: <br />- One uppercase letter, <br />- One lowercase
                    letter, and <br />- One digit.
                  </template>
                  <b-icon icon="information" size="is-small"> </b-icon>
                </b-tooltip>
              </div>
            </div>

            <div class="password-input-field">
              <InputVue
                :required="true"
                :showLable="false"
                :min="1"
                type="password"
                :submitted="submitted"
                :placeholder="'Confirm password'"
                :max="150"
                @onChangeName="onChangeConfirmPassword"
                label="Confirm Password"
              />
              <div
                v-if="submitted && !isConfirmPasswordInvalid && !isMatching"
                class="required-error"
              >
                New Password and Confirm Password are not matching.
              </div>
            </div>

            <div class="sign-in-container">
              <div class="buttons">
                <b-button
                  type="is-primary"
                  class="sign-in-button"
                  expanded
                  @click.prevent="handleSubmit"
                  >Reset Password</b-button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else class="main-div">
      <div class="create-new-password-form">
        <div class="inava-icon">
          <img
            :class="[
              productLogoLight ? 'inava-form-top-logo' : 'inava-form-top-logo-1'
            ]"
            :src="iNavaLogoLight"
          />
        </div>

        <div class="form-heading-container-1">
          <div class="form-heading">
            Your reset password request has expired.
          </div>

          <div class="sign-in-container">
            <div class="buttons">
              <b-button
                type="is-primary"
                class="sign-in-button"
                expanded
                @click="goSignIn()"
                >Sign In</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { metaTitle } from '../../constant/data'
import { clearStorage, re } from '../../util/util'
import Snackbar from '../Snackbar'
import InputVue from '../../views/Admin/components/inputNew.vue'

/** This component is used to render the Login form */
export default {
  name: 'LoginForm',
  components: {
    InputVue
  },
  data() {
    return {
      submitted: false,
      isResetTokenValid: false,
      form: {
        password: '',
        confirmPassword: '',
        resetToken: ''
      },
      isPasswordInvalid: true,
      isConfirmPasswordInvalid: true,
      isMatching: false,
      passwordInvalidCode: null
    }
  },
  props: {
    iNavaLogoLight: {
      type: String
    },

    productLogoLight: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('user', ['verifiedClient', 'validClientSlug']),
    ...mapState('filter', ['subscriptionRange', 'subscriptionGranulartiy'])
  },
  mounted() {
    // this.$refs.email.focus()
    window.localStorage.setItem('user-token', '')
    const params = {
      resetToken: this.$route.params.resetToken,
      clientSlug: window.location.host.split('.')[0]
    }
    this.$http.post('password/validateResetToken', params).then((response) => {
      if (response.data.success) {
        this.isResetTokenValid = true
      }
    })
  },
  methods: {
    ...mapActions('user', ['setUserDetails']),
    ...mapActions('common', ['update_user_preference_mode']),
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('filters', [
      'updateSubscriptionRange',
      'updateSubscriptionGranularity'
    ]),
    /**
     * This method redirects the user to the login page
     * @public
     */
    goSignIn() {
      this.$router.push('/login')
    },
    /** This method is used to validate the entered password
     * @public
     */
    onChangePassword(name, isValid) {
      this.form.password = name
      this.isPasswordInvalid = isValid
      if (!re.test(this.form.password)) {
        // this.isPasswordInvalid = false
        this.passwordInvalidCode = 1
      } else {
        this.passwordInvalidCode = null
      }
      this.checkConfirmPassword()
    },
    /** This method internally calls the *checkConfirmPassword()* method
     * @public
     */
    onChangeConfirmPassword(name, isValid) {
      this.form.confirmPassword = name
      this.isConfirmPasswordInvalid = isValid
      this.checkConfirmPassword()
    },
    /** This method is used to confirm that the user is aware of what was entered as the new password
     * @public
     */
    checkConfirmPassword() {
      if (this.form.password !== this.form.confirmPassword) {
        this.isMatching = false
      } else {
        this.isMatching = true
      }
    },
    /** This method submits the form
     * @public
     */
    async handleSubmit(e) {
      this.submitted = true
      if (
        !this.isPasswordInvalid &&
        !this.isConfirmPasswordInvalid &&
        this.isMatching &&
        this.passwordInvalidCode === null
      ) {
        try {
          const formData = this.form
          formData.clientSlug = window.location.host.split('.')[0]
          formData.resetToken = this.$route.params.resetToken
          const response = await this.$http.post('/password/reset', formData)
          if (response.data.success) {
            this.$router.push('/passwordRecovered')
          } else {
            if (response.data.status === 403) {
              clearStorage(false)
              this.messages = []
              this.messages.push(
                'Your subscription has expired. Please contact the Administrator.'
              )
            } else if (response.data.status === 401) {
              this.isResetTokenValid = false
            } else if (response.data.status === 305) {
              Snackbar({
                message: 'New password cannot be same as existing password.',
                type: 'is-danger'
              })
            } else {
              clearStorage(false)
              this.messages = []
              this.messages.push('Incorrect credentials')
            }
          }
        } catch (error) {
          clearStorage(false)
          // console.log(`login error: ${error}`)
          this.messages = []
          this.messages.push('Network error, please try again')
        }
      }
    },
    /** This method clears the error messages if they are present
     * @public
     */
    clearMessage() {
      this.messages = []
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-img-container {
  display: flex;
  justify-content: center;
  .powered-by-text {
    text-align: center;
    width: 100%;
    font: normal normal normal 12px/14px Quicksand;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal normal 13px/15px Quicksand;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal normal 14px/18px Quicksand;
    }
  }
}

.logo-img {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  span {
    border-right: 0.75px solid #c7c7c7;
    margin-left: 15px;
    height: 30px;
    margin-top: 5px;
  }
  .left-logo {
    width: 50%;
  }
  .right-logo {
    width: 50%;
    margin-left: 15px;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 145px;
    height: 60px;
    span {
      height: 50px;
    }
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 180px;
    height: 70px;
    span {
      height: 60px;
    }
  }
}
.create-new-password-container {
  width: 50%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-left-radius: 26px;
}

.img-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
}

.text-details {
  margin: 2% 10% 2% 10%;
  text-align: center;
  font: normal normal 13px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  // color: #303030;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal normal 15px/19px Quicksand;
    font-weight: 700;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal normal 18px/23px Quicksand;
  }
}

.corousal-text {
  height: 50%;
  background-color: #0e0f1b;
}
.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-corousal {
  height: 100%;
}
.corousal-item-new {
  height: 100%;
}

.form-container {
  height: auto;
}
.required-error {
  height: 24px;
  position: absolute;
  margin-top: -12px !important;
  // @media (min-width: 1450px) and (max-width: 1700px) {
  //   margin-top: 75px !important;
  // }
  //@media screen and (min-width: 1700px), screen and (min-height: 1080px) {
  //   margin-top: 84px !important;
  // }
}
.form-field-container {
  height: 100%;
}
.sign-in-container {
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 18px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 55px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 60px;
  }
  .sign-in-button {
    background: #00218a 0% 0% no-repeat padding-box;
    height: 35px;
    text-align: center;
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 7px;

    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      height: 40px;
    }
    @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
      font: normal normal 18px/23px Quicksand;
      height: 56px;
    }
  }
  .sign-in-button:hover {
    background: #00218a 0% 0% no-repeat padding-box;
  }
}

.email-field {
  margin-top: 0.5rem;
}
.corousal-item {
  height: 100% !important;
}

.form-heading {
  text-align: left;
  font: normal normal 24px/30px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 27px/34px Quicksand;
    font-weight: 600;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 34px/43px Quicksand;
    font-weight: 600;
  }
}
.header-text {
  text-align: left;
  font: normal normal 15px/16px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 17px/19px Quicksand;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    font: normal normal 20px/23px Quicksand;
  }
}
.main-div {
  width: 50%;
  height: 100%;
  color: #828282;
  background-color: #ac57e4;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #fff;
  border-top-right-radius: 26px;
}
.create-new-password-form {
  width: 68%;
  margin-left: 18%;
  height: 85%;
  @media (min-width: 1500px) {
    width: 70%;
    margin-left: 18%;
  }
}

.inava-icon {
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 130px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 190px;
  }
}

.inava-form-top-logo {
  width: 160px;
  margin-top: 13%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 200px;
    margin-top: 14%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 250px;
    margin-top: 15%;
  }
}

.inava-form-top-logo-1 {
  width: 60px;
  border-radius: 50%;
  background: #e8e8f0 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 7%;

  @media (min-width: 1450px) and (max-width: 1700px) {
    width: 70px;
    margin-top: 9%;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    width: 84px;
    margin-top: 12%;
  }
}
.form-heading-container {
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 20px;
    margin-top: 20px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 29px;
  }
}

.form-heading-container-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 35px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 40px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 55px;
  }
}

.email-input-field {
  width: 100%;
  height: 45px;
  margin-top: 26px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 55px;
    margin-top: 32px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 60px;
    margin-top: 42px;
  }
}
.password-input-field {
  width: 100%;
  height: 45px;
  margin-top: 26px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 55px;
    margin-top: 32px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    height: 60px;
    margin-top: 42px;
  }
}

.check-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.is-medium {
  min-height: 400px !important;
}

.form-subheading {
  margin-top: 7px;
  width: 100%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    margin-top: 10px;
  }
  @media screen and (min-width: 1700px), screen and (min-height: 1080px) {
    margin-top: 15px;
  }
}
</style>
<!-- <style lang="scss" src="../../views/Login/Login.scss" scoped /> -->
